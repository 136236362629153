import { makeRequest } from "../../../helpers/handlers";
import { makeRequest as makeRequestToModule } from "../moduleBuilder/handlers";
import { PROJECT_SECTION } from "../../../helpers/constants";
import { getJWToken, getUser } from "../../../helpers/handle_token";

const AssetPlugin = (editor) => {
    let messageAdded = false;
    function disableClick(row) {
        const customButton = row.querySelector('.custom-button');
        const close = row.querySelector('.custom-close');
        if (customButton && close) {
            customButton.style.pointerEvents = 'auto';
            close.style.pointerEvents = 'auto';
        }
    }

    function extractImageData(backgroundImage) {
        const match = backgroundImage.match(/url\(['"]?(.*?)['"]?\)/);
        return match ? match[1] : null;
    }

    editor.on('asset:open', function () {
        if (!editor.getSelected()) return;
        let category = "images";
        let imageManager = true; 
        if (editor.getSelected().getAttributes()["data-gjs-type"] === "link") {
            imageManager = false;
            category = "files";
        }
        const am = editor.AssetManager;
        const filteredAssets = am.getAll().filter((asset) => {
            return asset.get('category') === category;
        });
        am.render(filteredAssets);

        const header = document.getElementsByClassName("gjs-mdl-title")[0];
        header.innerHTML = imageManager ? "Select Image" : "Select File";
        let selectedRow;
        const error = document.querySelector(".error");
        if (error)
            error.parentElement.removeChild(error);
        if (!messageAdded) {
            const modalContent = document.querySelector(".gjs-asset-manager");
            if (modalContent) {
                const tipContainer = document.createElement('div');
                tipContainer.classList.add("tipContainer");
                const tip = document.createElement('span');
                tip.innerHTML = imageManager ? 
                    "Supported files: jpeg, jpg, webp, png." : 
                    "Supported files: pdf, doc, docx , xls, xlsx, txt.";
                tip.innerHTML += " Max upload size is 2 MB.";
                tip.classList.add("tip");
                tipContainer.appendChild(tip);

                modalContent.prepend(tipContainer);
                messageAdded = true;
            }
        } else {
            const existingTip = document.querySelector(".tip");
            if (existingTip) {
                existingTip.innerHTML = imageManager ? 
                    "Supported files: jpeg, jpg, webp, png." : 
                    "Supported files: pdf, doc, docx , xls, xlsx, txt.";
                existingTip.innerHTML += " Max upload size is 2 MB.";
            }
        }

        function findImage(row) {
            const imageHolder = row.querySelectorAll('.gjs-am-preview');
            if (!editor.getSelected()) return;
            let containerImgData = editor.getSelected().getAttributes()['src'];
            imageHolder.forEach((imageHolder) => {
                const backgroundImage = window.getComputedStyle(imageHolder).backgroundImage;
                const imageData = extractImageData(backgroundImage);
                if (containerImgData === imageData) {
                    // containerImgData = imageData;
                    row.style.background = "#4E4C67";
                    selectedRow = row;
                }
            });
        }

        function addCustomButton(row) {
            if (row.dataset.customButtonAdded) return;

            const customButton = document.createElement('button');
                customButton.className = 'custom-button';
                customButton.innerHTML = 'Use';
                customButton.onclick = () => {
                    if (selectedRow !== row) {
                        if (row.style) {
                            row.style.background = "#4E4C67";
                        } else {
                            row.setAttribute('style', 'background: #4E4C67;');
                        }

                        if (selectedRow && selectedRow.style) {
                            selectedRow.style.background = "#282632";
                        } else if (selectedRow) {
                            selectedRow.setAttribute('style', 'background: #282632;');
                        }

                        selectedRow = row;
                        makeRequest(PROJECT_SECTION.SECTION, PROJECT_SECTION.SAVE, {
                            projectData: localStorage.getItem("gjsProject"), 
                            projectId: localStorage.getItem('projectId'),
                            username: getUser(getJWToken())
                        });
                    }
                }
                //close button
                const customClose = document.createElement('button');
                customClose.className = 'custom-close';
                customClose.innerHTML = 'Remove';
                customClose.setAttribute("data-toggle", "asset-remove");
                
                row.querySelector(".gjs-am-close").remove();
                row.appendChild(customButton);
                row.appendChild(customClose);
                row.style.background = "#282632";
                row.dataset.customButtonAdded = true;
        };

        const existingRows = document.querySelectorAll('.gjs-am-asset-image');
        existingRows.forEach(currRow => {addCustomButton(currRow); disableClick(currRow); findImage(currRow);});

        const assetManagerContainer = document.querySelector('.gjs-am-assets');
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.addedNodes.length > 0) {
                    mutation.addedNodes.forEach((addedNode) => {
                        if (addedNode.classList && addedNode.classList.contains('gjs-am-asset-image')) {
                            addCustomButton(addedNode);
                            disableClick(addedNode);
                        }
                    });
                }
            });
        });

        const observerConfig = { childList: true };
        observer.observe(assetManagerContainer, observerConfig);

        editor.on('asset:close', function () {
            observer.disconnect();
        });
    });

    editor.on('asset:remove', (asset) => {
        let assetName = asset.attributes['name'];
        if (localStorage.getItem("isModuleBuilderActive")) {
            makeRequestToModule("POST", "images", "deleteImage", { name: assetName });
            return;
        }
        makeRequest(PROJECT_SECTION.SECTION, PROJECT_SECTION.DELETE_IMAGE, { username: getUser(getJWToken()), name: assetName})
        .then(response => {
            // TODO Add check for response code
            editor.AssetManager.remove(asset);
        });
    })

    editor.on('asset:open', function () {
        if (!editor.getSelected()) return;
        let imageManager = true;
        if (editor.getSelected().getAttributes()["data-gjs-type"] === "link") {
            imageManager = false;
        }
        const urlImageBtn = document.querySelectorAll('.gjs-btn-prim');
        const formContainer = document.querySelectorAll('.gjs-am-assets-header');
        const imagesContainer = document.querySelectorAll('.gjs-am-assets-cont');

        urlImageBtn[0].textContent = imageManager ? "Add image by URL" : "Add file by URL" ;

        imagesContainer.forEach(container => {
            container.style.maxHeight = '250px';
        });

        formContainer.forEach(container => {
            if (container.querySelector('h1') !== null) return;

            const h1Element = document.createElement('h1');
            h1Element.textContent = imageManager ? 
                'You can use image from here:' : 'You can use file from here:';
            h1Element.style.textAlign = 'center';
            h1Element.style.marginTop = '8px';
            h1Element.style.fontSize = "18px";

            container.appendChild(h1Element);
        });
    });
}

export default AssetPlugin;