import "./goBackButton.scss";
import Icon from "../Icon/Icon";

interface Props {
    top?: number,
    left?: number,
    clickEvent?: (event: React.MouseEvent) => void
};

const GoBackButton: React.FC<Props> = ({clickEvent, left = 0, top = 0}) => {
    return (
        <button style={{top: `${top}px`, left: `${left}px`}} onClick={(event) => clickEvent?.(event)} className="action__arrow--button account--action__arrow--button">
            <Icon name="arrow-left" className="action__arrow" color="#CDFD34" />
        </button>
    );
};

export default GoBackButton;