import { useEffect, useRef, useState } from "react";
import { makeRequest } from "../../helpers/handlers";
import { useNavigate } from "react-router";
import { getEmail, getIsActive, getJWToken, getUser } from "../../helpers/handle_token";
import { USER_SECTION } from "../../helpers/constants";
import Cookies from "js-cookie";
import Icon from "../../components/Icon/Icon";
import { useQueryClient } from "react-query";
import { logout } from "../../helpers/handlers";
import GoBackButton from "../../components/goBackButton/goBackButton";
import "./confirmEmail.scss";

const ConfirmEmail = () => {
    const codeRef = useRef(null);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [error, setError] = useState("");

    function removeCache() {
        queryClient.clear();
    }

    function handleSubmit(event) {
        event.preventDefault();
        const username = getUser(getJWToken());
        makeRequest(USER_SECTION.SECTION, USER_SECTION.VERIFY_EMAIL, {
            code: codeRef.current.value,
            username: username
        })
            .then(data => {
                if (data['code'] == 1033) {
                    // console.log(data);
                    setError(data['message']);
                    return;
                }

                makeRequest(USER_SECTION.SECTION, USER_SECTION.RELOG, {
                    username: username,
                })
                    .then((data) => {
                        if (data["code"] !== 0) {
                            return;
                        }
                        Cookies.set("sessionJWT", data["data"]);
                        navigate("/projects");
                    })
            })
    }

    function resendCode() {
        makeRequest(USER_SECTION.SECTION, USER_SECTION.SEND_VERIFICATION_CODE, {
            username: getUser(getJWToken()),
            email: getEmail(getJWToken())
        })
            .catch((err) => {
                //TODO: display error.message
                // navigate("/error", { state: { err: "Unable to resend code!" } });
            })
    }

    useEffect(() => {
        if (!getJWToken())
            navigate("/");
        if (getIsActive(getJWToken()))
            navigate("/projects");
    }, [])

    return (
        <div className="verification-code grid-x align-center-middle">
            <GoBackButton clickEvent={() => { removeCache(); logout(); navigate("/login") }} top={100} left={216} />
            <div className="cell">
                <form onSubmit={handleSubmit} className="grid-x grid-margin-y grid-margin-x align-center-middle">
                    <h4 className="cell text-center bold" style={{ color: "white" }}>
                        Verification code:
                    </h4>
                    <input placeholder="Code..." type="text" ref={codeRef} className="cell action__input small-10 medium-6" />
                    {error != "" ? <span>{error}</span> : <></>}
                    <div className="cell"></div>
                    <button type='submit' className="action cell small-10 medium-3">
                        Submit
                    </button>
                    <button type="submit" onClick={resendCode} className="action cell small-10 medium-3">
                        Resend code
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ConfirmEmail;