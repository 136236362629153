import { getComponent } from "../../../helpers/builderFunctions";
import { makeRequest } from "../../../helpers/handlers";
import { makeRequest as makeRequestToModule } from "../moduleBuilder/handlers";
import $ from 'jquery';

export const ViewerPlugin = (editor, { 
    setImageModalOpen, 
    imageModalContentRef,
    addToCart,
    getAllProducts,
    navbar,
    footer
}) => {
    //? event functions
    function fullscreenImage(component) {
        const src = component.getAttributes()['src'];
        setImageModalOpen(true);
        imageModalContentRef.current.style.backgroundImage = `url(${src})`;
    }

    function goToLink(component) {
        const href = component.getAttributes()["href"];
        if(!href) return;
        
        if(href[0] === "#"){
            const linkTarget = getComponent(editor, href);
            if(!linkTarget) return;
            linkTarget.getEl().scrollIntoView();
        }
        else if(href.includes("page:")){
            const pageId = href.replace("page:", "");
            editor.Pages.select(pageId);
        }
        else {
            window.open(href, "_blank");
        }
    }
    
    function filter(component) {

    }

    function apiRequest(component) {
        console.log(component);
        const attributes = component.getAttributes(),
            section = attributes['data-apiSection'],
            command = attributes['data-apiCommand'],
            expectedParams = {
                checkout: [{
                        name: 'storeId',
                        type: 'attribute'
                    }, {
                        name: 'products',
                        type: 'localStorage'
                    }, {
                        name: 'userData',
                        type: 'input'
                }],
                send_email: [{
                        name: 'email',
                        type: 'attribute'
                    }, {
                        name: 'userEmail',
                        type: 'input'
                    }, {
                        name: 'header',
                        type: 'input'
                    }, {
                        name: 'textContent',
                        type: 'input'
                }]
            };
        let requestParams = {};
        
        if(!expectedParams[command])
            return;

        //? foreach all the expected params and add them to requestParams depending on their type
        expectedParams[command].forEach(param => {
            const form = component.parent().parent().getEl();
            let value;
            switch(param['type']) {
                case 'input':
                    const input = $(form).find(`[name=${param['name']}]`)[0];
                    value = input.value;
                    break;
                case 'attribute':
                    value = form.getAttribute(`data-${param['name']}`);
                    break;
                case 'localStorage':
                    value = JSON.parse(localStorage.getItem(param['name']));
                    break;
                default:
                    break;
            }
            
            requestParams[param['name']] = value;
        });
        
        console.log(requestParams);
        if(localStorage.getItem("isModuleBuilderActive")) {
            makeRequestToModule('POST', section, command, requestParams);
            return;
        }
        
        makeRequest(section, command, requestParams, 'POST');
    }

    function addProduct(component) {
        //TODO: fix / test
        const attributes = component.getAttributes(),
            productData = JSON.parse(attributes['data-productData']);
        addToCart(attributes['data-storeId'], productData);
        console.log(getAllProducts(attributes['data-storeId']));
    }

    //? configuration functions
    function setNavbarAndFooterRefs() {
        const pages = editor.Pages.getAll();
        let index = 0;
        do {
            editor.Pages.select(pages[index]['id']);

            const navbarPlaceholder = editor.DomComponents.getComponents()
                .filter(component => 
                    component.getClasses().includes('navbarPlaceholder') && 
                    component.get('components').length > 0)[0],
                footerPlaceholder = editor.DomComponents.getComponents()
                .filter(component => 
                    component.getClasses().includes('footerPlaceholder') &&
                    component.get('components').length > 0)[0];

            console.log(navbarPlaceholder);
            if(navbarPlaceholder && !navbar.current) {
                const navbarPlaceholderChildren = navbarPlaceholder.components();
                if(navbarPlaceholderChildren.length > 0)
                    navbar.current = navbarPlaceholderChildren['models'][0];
            }

            if(footerPlaceholder && !footer.current) {
                const footerPlaceholderChildren = footerPlaceholder.components();
                if(footerPlaceholderChildren.length > 0)
                    footer.current = footerPlaceholderChildren['models'][0];
            }

            index += 1;
        }
        while(index < pages.length && (!navbar.current || !footer.current));
    }

    function selectPageFromUrl() {
        const pageUrls = JSON.parse(localStorage.getItem("pageUrls"));
        let currentUrl = window.location.pathname.split("/").slice(-1)[0];
        currentUrl = currentUrl.replace("%20", " ");

        if(!pageUrls) {
            const selectedPage = editor.Pages.getAll().filter(page => page['attributes']['name'] === currentUrl)[0];
            if(!selectedPage) return false;

            editor.Pages.select(selectedPage);
            return true;
        }
        const selectedPageObject = pageUrls.filter(url => url['url'] === currentUrl)[0];

        if(!selectedPageObject) return false;
        const selectedPage = editor.Pages.getAll().filter(page => page['id'] === selectedPageObject['id'])[0];

        if(!selectedPage) return false;

        editor.Pages.select(selectedPage['id']);
        return true;
    }

    function hideAddSectionPlusses() {
        const plusAbove = getComponent(editor, ".plusAbove"),
            plusBelow = getComponent(editor, ".plusBelow");
        
        if(plusAbove)
            plusAbove.setStyle({ display: "none" });
        if(plusBelow)
            plusBelow.setStyle({ display: "none" });
    }

    function returnMapVideoPointerEvents(component) {
        //? remove class .gjs-no-pointer{ pointer-events-none; }
        if(component.get('type') === 'video' || component.get('type') === 'map') {
            const videoElement = component.getEl().children[0];
            videoElement.className = "";
        }
    }

    function appendNavbarAndFooter() {
        console.log(navbar.current, footer.current)
        //? add the navbar to navbarPlaceholder
        if(navbar.current) {
            //? create navbarPlaceholder if it does not exist
            let navbarPlaceholder = getComponent(editor, ".navbarPlaceholder");
            if(!navbarPlaceholder) {
                navbarPlaceholder = editor.addComponents({
                    tagName: "div",
                    attributes: {
                        class: `navbarPlaceholder`,
                        'data-type': 'placeholder'
                    },
                    components: []
                }, { at: 0 })[0];
            }
            navbarPlaceholder.empty();
            navbarPlaceholder.append(navbar.current);   
        }

        
        //? add the footer to footerPlaceholder
        if(footer.current) {
            //? create footer placeholder if it does not exist
            let footerPlaceholder = getComponent(editor, ".footerPlaceholder");
            if(!footerPlaceholder) {
                footerPlaceholder = editor.addComponents({
                    tagName: "div",
                    attributes: {
                        class: `footerPlaceholder`,
                        'data-type': 'placeholder'
                    },
                    components: []
                })[0];
            }
            footerPlaceholder.empty();
            footerPlaceholder.append(footer.current);
        }
    }

    function setUrlToSelectedPage(selectedPage) {
        //? check if the page has a set url: if not set the url to .../${page.name}
        const pageUrls = JSON.parse(localStorage.getItem("pageUrls")), 
            currPathname = window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/"));

        let selectedPageUrl = selectedPage['attributes']['name'];
        if(pageUrls)  {
            const selectedPageUrlObject = pageUrls.filter(url => url['id'] === selectedPage['id'])[0];
            if(!!selectedPageUrlObject)
                selectedPageUrl = selectedPageUrlObject['url'];
        }
        //? if the page url is not set, set the current url to the page name
        console.log(selectedPageUrl);
        history.pushState({page: selectedPage['id']}, 'Page', `${currPathname}/${selectedPageUrl}`);
    }

    //? events
    editor.on("load", () => {
        

        setNavbarAndFooterRefs();
        const response = selectPageFromUrl();
        //? if url does not match with any page go to page 404 or home
        if(!response) {
            const page404 = editor.Pages.getAll().filter(page => page['id'] === 404)[0];
            if(page404)
                editor.Pages.select(page404['id']);
            else 
                editor.Pages.select(editor.Pages.getMain());
        }
        hideAddSectionPlusses();
    });

    editor.on('page:select', page => {
        if(!navbar.current || !footer.current) return;

        setUrlToSelectedPage(page);
        appendNavbarAndFooter();

        const wrapper = editor.Components.getWrapper();
        wrapper.set({
            selectable: false,
            hoverable: false,
            editable: false
        })
    })
    
    editor.on("component:mount", component => {
        returnMapVideoPointerEvents(component);

        //? remove builder edit functions
        component.set({ selectable: false, hoverable: false, editable: false })

        //? setup component events
        const eventType = component.getAttributes()['data-eventType'],
            functionName = component.getAttributes()['data-eventFunction'];

        if(!functionName || !eventType) return;
        console.log(eventType, functionName);
        let eventFunction = () => eval(`${functionName}(component)`);
        component.getEl().addEventListener(eventType, eventFunction);
        editor.DomComponents.getWrapper().set({badgable: false, selectable: false, hoverable: false})
    })
}