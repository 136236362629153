import $ from "jquery";
import { grapesjs } from "grapesjs";
import { useEffect, useRef, useContext } from "react";
import { importPicture } from "../../../helpers/handlers";
import customCodePlugin from "grapesjs-custom-code";
import {plugin as basicBlocks} from "grapesjs-blocks-basic";
import pluginTooltip from 'grapesjs-tooltip';
import { EventPlugin } from "../plugin/EventPlugin";
import { PanelPlugin } from "../plugin/PanelPlugin";
import { StylePlugin } from "../plugin/StylePlugin";
import { BlockPlugin } from "../plugin/BlockPlugin";
import { TraitPlugin } from "../plugin/TraitPlugin";
import { UndoPlugin } from "../plugin/UndoPlugin";
import { TypePlugin } from "../plugin/TypePlugin";
import { moduleImportImage } from "../moduleBuilder/handlers";
import { defaultCss } from "../../../helpers/builderFunctions";
import { BuilderContext } from "../../../helpers/constants";
import { makeRequest } from "../../../helpers/handlers";
import { makeRequest as makeRequestToModule } from "../moduleBuilder/handlers";
import { PROJECT_SECTION } from "../../../helpers/constants";
import { ColorOnlyStyleType } from "../plugin/ColorOnlyStyleType";
import AnchorHover from "./AnchorHover";
import AssetPlugin from "../plugin/AssetPlugin";
import {plugin as backgroundPlugin} from 'grapesjs-style-bg';
import { getJWToken, getUser } from "../../../helpers/handle_token";

const BuilderContainer = () => {
    const {
        editor, setEditor, 
        setReplacingElement,
        managerRef,
        changeManager,
        setSectionOptions,
        builderMode,
        undoManager
    } = useContext(BuilderContext);
    const hoveredComponent = useRef(null);

    function loadAssets(editor) {
        if (!editor) return;
        if (localStorage.getItem("isModuleBuilderActive")) {
            makeRequestToModule("POST", "images", "getAllImages")
            .then(response => {
                const existingAssets = editor.AssetManager.getAll().map(asset => asset.get('src'));
                response.data.forEach(image => {
                    let category;
                    let type = image["type"].split("/");
                    if (type[0] === "image") {
                        category = "images";
                    } else {
                        category = "files";
                    }
                    if (!existingAssets.includes(image["url"])) {
                        editor.AssetManager.add({
                            category: category,
                            type: type[0],
                            name: image["name"],
                            src: image["url"],
                        });
                    }
                });
            });
            return;
        }
        makeRequest(PROJECT_SECTION.SECTION, PROJECT_SECTION.GET_ALL_IMAGES, {
            username: getUser(getJWToken())
        })
        .then(response => {    
            const existingAssets = editor.AssetManager.getAll().map(asset => asset.get('src'));
    
            response.data.forEach(image => {
                let category;
                let type = image["type"].split("/");
                if (type[0] === "image") {
                    category = "images";
                } else {
                    category = "files";
                }
                if (!existingAssets.includes(image["url"])) {
                    editor.AssetManager.add({
                        category: category,
                        name: image["name"],
                        type: type[0],
                        src: image["url"]
                    });
                }
            });
        });
    }
    useEffect(() => {
        if(!editor) return;

        const types = editor.DomComponents.getTypes(),
            currType = types.filter((type) => type['id'] === 'facebook');
    }, [editor])

    useEffect(() => {
        if (editor || localStorage.getItem("gjsProject") == null || !setEditor) {
            return;
        }
            
        const e = grapesjs.init({
            container: '#gjs',
            richTextEditor: {
                enable: true
            },
            fromElement: true,
            allowScripts: 1,
            devicePreviewMode: 0,
            selectorManager: {
                componentFirst: (builderMode !== "admin"),
                selectors: ['class', 'id']
            },
            canvas: {
                scripts: [
                    "https://platform.twitter.com/widgets.js",
                    "https://embed.reddit.com/widgets.js",
                    "https://www.instagram.com/embed.js",
                    "https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.9.4/Chart.js",
                    "https://www.gstatic.com/charts/loader.js",
                ],
                styles: [
                    "https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.8.0/Chart.css"
                ]
            },
            pageManager: {
                pages: [
                    {
                        id: Math.random().toString(36).slice(2),
                        name: "Home page",
                        component: ``,
                    }
                ]
            },
            layerManager: {
                appendTo: "#js-builder__layer-manager",
                root: "#wrapper",
                showWrapper: false,
                sortable: true,
                hidable: true,
            },
            styleManager: {
                appendTo: "#js-builder__style-manager",
                sectors: [],
                clearProperties: false,
                highlightChanged: false,
                highlightComputed: false,
                avoidComputed: false, 
                showComputed: true,
                hideNotStylable: true,
            },
            blockManager: {
                appendTo: "#js-builder__block-manager",
                categories: [{
                    open: false,
                }]
            },
            assetManager: {
                storageType: '',
                // storeOnChange: true,
                // storeAfterUpload: true,
                assets: [],
                uploadFile: (event) => {
                    const handleData = (response) => {
                        if (response["code"] !== 0) {
                            if (!document.querySelector(".errorAsset")) {
                                const error = document.createElement("div");
                                error.classList.add("errorAsset");
                                error.innerHTML = response["message"];
                                const container = document.querySelector(".gjs-asset-manager");
                                container.prepend(error);
                            }
                            return;
                        } 
                        const error = document.querySelector(".errorAsset");
                        if (error) {
                            error.parentElement.removeChild(error);
                        }
                        $.each(response["data"], function (key, value) {
                            console.log(value['url']);
                            let category;
                            const type = value["type"].split("/");
                            if (type[0] === "image") {
                                category = "images";
                            } else {
                                category = "files";
                            }
                            e.AssetManager.add({
                                category: category, 
                                name: value["name"],
                                type: type[0],
                                src: value["url"]
                            });
                            
                        });
                    };
                    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
                    let formData = new FormData();
                    for (let i in files) {
                        formData.append(`file-${i}`, files[i]); //containing all the selected images from local
                    }
                    let requestPromise;
                    if (localStorage.getItem("isModuleBuilderActive")) {
                        requestPromise = moduleImportImage("POST", "images", "save", formData) // adjust URL
                    } else {
                        requestPromise = importPicture(formData, builderMode === "admin");
                    }
                    requestPromise.then(response => handleData(response));
                },
            },
            deviceManager: {
                appendTo: "#devices",
                devices: [{
                    id: "Desktop", name: "Desktop", width: "100vw"
                }, {
                    id: "Tablet", name: "Tablet", width: "700px",
                }, {
                    id: "Phone", name: "Phone", width: "400px"
                }]
            },
            panels: {
                defaults: []
            },
            storageManager: {
                type: 'local',
                autosave: true, // Store data automatically
                autoload: true, // Autoload stored data on init
                stepsBeforeSave: 1,
                options: {
                    local: { key: `gjsProject` }
                },
            },
            traitManager: {
                appendTo: "#js-builder__settings-manager",
                traits: []
            },
            plugins: [backgroundPlugin, AssetPlugin, ColorOnlyStyleType, TypePlugin, EventPlugin, PanelPlugin, StylePlugin, BlockPlugin, TraitPlugin, basicBlocks, pluginTooltip, UndoPlugin],
            pluginsOpts: {
                [UndoPlugin]: {
                    undoManager: undoManager
                },
                [PanelPlugin]: {
                    builderMode: builderMode,
                    changeManager: changeManager,
                    managerRef: managerRef,
                    undoManager: undoManager
                },
                [EventPlugin]: {
                    hoveredComponent: hoveredComponent,
                    changeManager: changeManager,
                    setSectionOptions: setSectionOptions,
                    builderMode: builderMode,
                    setReplacingElement: setReplacingElement,
                },
                [TraitPlugin]: {
                    builderMode: builderMode,
                    changeManager: changeManager
                },
                [StylePlugin]: {
                    builderMode: builderMode
                },
                [customCodePlugin]: {
                    placeholderScript: this,
                },
                [backgroundPlugin]: {}
            },
            protectedCss: defaultCss
        });

        e.addComponents({
            tagName: "div",
            components: "",
            attributes: {
                id: "image-preview",
                "data-dont-show": "template"
            },
            hoverable: false,
            highlightable: false,
            locked: true,
            selectable: false,
            deletable: false,
        });

        loadAssets(e);

        e.Blocks.getCategories().forEach(ct => ct.set('open', false))
        setEditor(e);
    }, [editor]);
    
    return (
        <>
            <AnchorHover/>
            <div id="gjs" />
        </>
    );
}

export default BuilderContainer;